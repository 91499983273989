<template>
  <div class="signUpNotes">
    <h4>1、赛事说明</h4>
    <p class="indent">本赛事参赛规则由大赛组委会创设，任何参赛者提交参赛作品前均应先阅读本参赛通知。参赛者使用大赛网站提交参赛作品即意味着参赛者已阅读、理解并同意接受本参赛规则的各项约束，并遵守所有适用的法律和法规之规定。</p>

    <h4 class="pdTop">2、赛事解释</h4>
    <p class="indent">大赛组委会享有对各项赛事规则的解释权。特殊问题及未尽事宜，以大赛组委会最终解释或认定为准。</p>

    <h4 class="pdTop">3、参赛作品</h4>
    <p class="indentDouble">1）参赛作品应遵守国家有关法律法规及行业规范；不涉密；符合我国民族文化传统、公共道德价值等要求。凡不符合要求的作品，将被取消参赛资格或参赛成绩。</p>
    <p class="indentDouble">2）参赛作品提交时须同时提交团队其他成员姓名，否则该作品获奖时将视作无其他团队成员。</p>
    <p class="indentDouble">3）每名参赛者或参赛团体就同一命题的参赛作品只限提交一件；同一参赛作品只能参与一个大赛奖项的评选；重复上传的作品如在参赛作品提交截止时间前未能及时删除，将被取消参赛资格。</p>
    <p class="indentDouble">4）大赛组委会不承担参赛作品在提交过程中所造成的作品丢失、毁损责任及其他由不可抗力因素造成的任何参赛资料的遗失、错误或毁损责任。</p>

    <h4 class="pdTop">4、版权要求及归属</h4>
    <p class="indentDouble">1）参赛作品均需为参赛者或参赛团体自行创意的原创作品，为参赛者或参赛团体所有；如参赛作品系依据其他作品改编或引用其他作品，参赛者或参赛团体应保证其已合法享有原作品的改编权或使用权，并保证大赛组委会及合作单位亦合法拥有对参赛作品的使用权。</p>
    <p class="indentDouble">2）大赛组委会不负责核实参赛作品的所有权。若参赛作品发生任何侵犯第三方人身权、知识产权的行为，参赛者/参赛团体和参赛作品将被立即取消参赛或获奖资格，由此引发的一切法律责任和损失应由参赛者或参赛团体自行承担。</p>
    <p class="indentDouble">3）依据国家有关法律法规，凡主动提交作品的参赛者或参赛团体，大赛组委会认为其已经对所提交的作品版权归属作如下不可撤销声明。</p>

    <h4 class="pdTop">5、原创声明</h4>
    <p class="indent">参赛作品是参赛者或参赛团体的原创作品，未侵犯他人的任何专利、著作权、商标权及其他知识产权；该作品未在报刊、杂志、网站（含开源社区）及其他媒体公开发表；该作品若已申请专利或者其他版权证明，专利或版权拥有者需为参赛者本人。</p>

    <h4 class="pdTop">6、免责声明</h4>
    <p class="indent">为了推广需要，拥有参赛者或参赛团体的肖像使用权（包括但不限于摄影和录像）及作品使用权，进行公关播送、公开传播、复印、复制、出版或在一切相关活动中进行展示利用；比赛中所涉及肖像权、名誉权、隐私权、著作权、商标权等引起的纠纷，一律由参赛者或参赛团体承担法律责任，大赛组委会概不负责。</p>
    <div class="noteFooter">
      <Checkbox v-model="isChecked">
        <span>承诺书：本人已详细阅读上述内容，并保证遵守有关规定，本人提供的设计作品为原创性设计，如果得以入选，本人将授予大赛组委会将此作品收藏、展出、出版和应用的权利。</span>
      </Checkbox>
      <p><Button class="signUpBtn" :class="{'redBg': type=='changdu'}" shape="circle" @click="gotoSign">我要报名</Button></p>
    </div>  
  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      isChecked: false
    };
  },
  methods: {
    gotoSign() {
      if (this.isChecked) {
        let name = this.$route.name;
        if (this.type == "changdu") {
          if (name != "ChangDuSignUp") {
            this.$router.push("/chang_du/sign_up");
          }
        } else {
          if (name != "StarSignUp") {
            this.$router.push("/dragon_star/sign_up");
          }
        }
        this.$parent.$parent.isShow = false;
      } else {
        this.$Message.warning("请勾选底部的已阅读并同意承诺内容");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.signUpNotes {
  line-height: 2em;
  padding: 0 15px;
  text-align: left;
  position: relative;

  .pdTop {
    padding-top: 20px;
  }
  .indent {
    text-indent: 12px;
  }
  .indentDouble {
    text-indent: 24px;
  }
  .noteFooter {
    // position: fixed;
    // bottom: 20%;
    // left: 10%;
    // right: 10%;
    text-align: center;
    padding: 20px 50px 10px;
    // background: #ffffff;

    .signUpBtn {
      padding: 5px 50px;
      margin-top: 20px;
    }
    .redBg {
      background: linear-gradient(to right, #d42a1b, #c43a2e);
      border-color: #d42a1b;
      box-shadow: 0 2px 5px 2px rgba($color: #d42a1b, $alpha: 0.3);
    }
  }
}
</style>