<template>
  <div class="dragonHome">
    <div class="dragonNav">
      <div class="logo">龙腾之星 | 第五届</div>
      <div class="navDiv">
        <router-link class="nav" to="/dragon_star/index">大赛首页</router-link>
        <router-link class="nav" to="/dragon_star/about">大赛详情</router-link>
        <span class="nav" @click="toSignUp">我要参赛</span>
      </div>
      <div class="signUp">
        <template v-if="!gIsLogin()">
          <Button :to="`${officialWebsiteUrl}#/register?fromUrl=${websiteBaseUrl}login`">注册</Button>
          <Button to="/login" class="mgLeft">登录</Button>
        </template>
        <template v-else>
          <Dropdown>
            <div class="loginWrap">
              <img v-if="userInfo.avatar" class="userAvatar" :src="getImageUrl(userInfo.avatar, 'avatar')" alt="">
              <span class="userName">{{userInfo.trueName}}</span>
            </div>
            <DropdownMenu slot="list">
              <DropdownItem><span @click="improve">完善信息</span></DropdownItem>
                <!-- <router-link class="downItemColor" :to="/personal_info" target="_blank">完善信息</router-link> -->
                <!-- <a :href="`${officialWebsiteUrl}#/login`" target="_blank" class="downItemColor">完善信息</a> -->
              <DropdownItem><span @click="logOut">退出登录</span></DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </div>
    </div>
    <div class="center" style="padding:10px; display:none;">温馨提示：应参赛学生申请，经大赛组委会研究决定，提交平台开放至2024年4月30日24点</div>
    <router-view></router-view>
    <!-- 联系我们 -->
    <div class="contactWrap">
      <Row :gutter="50">
        <Col :lg="8" :sm="24">
          <p class="footerTitle">联系组委会</p>
          <p><span class="footerLabel">电话</span>010-83681553</p>
          <p><span class="footerLabel">手机</span>15810007797</p>
          <p><span class="footerLabel">邮箱</span>601@ddfddf.org</p>
          <p><span class="footerLabel">地址</span>北京市大兴区金苑路26号3号楼</p>
          <p><span class="footerLabel">邮编</span>102628</p>
        </Col>
        <Col :lg="8" :sm="24">
          <p class="footerTitle mobilePdtop">商务合作</p>
          <p><span class="footerLabel">电话</span>010-83681552</p>
          <p><span class="footerLabel">手机</span>15810007797</p>
          <p><span class="footerLabel">邮箱</span>601@ddfddf.org</p>
          <p><span class="footerLabel">地址</span>北京市大兴区金苑路26号3号楼</p>
          <p><span class="footerLabel">邮编</span>102628</p>
        </Col>
        <Col :lg="8" :sm="24">
          <p class="footerTitle mobilePdtop">公益合作</p>
          <p><span class="footerLabel">电话</span>010-83681553</p>
          <p><span class="footerLabel">手机</span>15810007797</p>
          <p><span class="footerLabel">邮箱</span>601@ddfddf.org</p>
          <p><span class="footerLabel">地址</span>北京市大兴区金苑路26号3号楼</p>
          <p><span class="footerLabel">邮编</span>102628</p>
        </Col>
      </Row>
    </div>
    <!-- 友情链接 -->
    <div class="linkWrap">
      <p class="linkTitle">友情链接</p>
      <Row :gutter="30">
        <Col :lg="4" :md="6" :sm="12">
          <p><a href="https://www.hebust.edu.cn/" class="link" target="_blank">河北科技大学</a></p>
          <p><a href="http://www.syidid.com" class="link" target="_blank">沈阳创新设计服务中心</a></p>
          <p><a href="http://www.cqida.net/" class="link" target="_blank">重庆工业设计协会</a></p>
        </Col>
        <Col :lg="4" :md="6" :sm="12">
          <p><a href="http://xa-du.com" class="link" target="_blank">西安设计联合会</a></p>
          <p><a href="http://www.51jishu.com/sjzjcmsweb/" class="link" target="_blank">浙江省工业设计协会</a></p>
        </Col>
        <Col :lg="4" :md="6" :sm="12">
          <p><a href="http://www.ddfddf.org/" class="link" target="_blank">北京光华设计发展基金会</a></p>
          <p>绿色设计研究院</p>
          <p>扬州绿色设计协会</p>
        </Col>
        <Col :lg="4" :md="6" :sm="12">
          <p><a href="https://artopgroup.com/" class="link" target="_blank">浪尖设计集团有限公司</a></p>
          <p><a href="http://www.kanglvholding.com" class="link" target="_blank">康旅控股集团</a></p>
          <p><a href="http://www.agio-cn.com" class="link" target="_blank">顺德锡山家具集团</a></p>
        </Col>
        <Col :lg="4" :md="6" :sm="12">
          <p><a href="http://www.visionunion.com/" class="link" target="_blank">视觉同盟</a></p>
          <p><a href="http://designmag.cn/" class="link" target="_blank">设计杂志</a></p>
          <p><a href="https://shijue.me/" class="link" target="_blank">视觉中国</a></p>
        </Col>
        <Col :lg="4" :md="6" :sm="12">
          <p><a href="https://meia.me/" class="link" target="_blank">美啊直播</a></p>
          <p><a href="https://ixdc.org/" class="link" target="_blank">IXDC</a></p>
        </Col>
      </Row>
    </div>
    <!-- copyright -->
    <div class="copyright center">
      Copyright @ 2020龙腾之星全球大学生绿色设计大赛组委会 保留一切权利<br class="lgHidden" /><span class="smHidden"> ｜ </span>京ICP备13011399号-4<span class="smHidden" style="display:none"> | 京公网安备 12345678909876号</span>
    </div>
    <BackTop><div class="backTop"></div></BackTop>

    <!-- 报名须知 -->
    <Modal v-model="isShow"
      title="龙腾之星报名须知"
      :mask-closable="false"
      :footer-hide="true"
      class-name="notesModal"
      width="80">
      <SignUpNotes />
    </Modal>
  </div>
</template>

<script>
import SignUpNotes from "@/components/SignUpNotes.vue";
import { websiteBaseUrl, officialWebsiteUrl } from "@/config/env";
export default {
  components: {
    SignUpNotes
  },
  data() {
    return {
      websiteBaseUrl: websiteBaseUrl,
      officialWebsiteUrl: officialWebsiteUrl,
      isShow: false,
      userInfo: {}
    };
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo;
    document.title = "龙腾之星全球大学生绿色设计大赛";

    // this.$Modal.info({
    //   title: "提示",
    //   content: "龙腾之星 · 第三届(2021) 全球大学生绿色设计大赛将于2021年8月15日正式开通官方报名通道！",
    //   okText: "确定",
    //   onOk: function() {
        
    //   }
    // });
  },
  methods: {
    // // 下载证书
    // download() {
    //   if (this.gIsLogin()) {
    //     this.$router.push("/certificate");
    //   } else {
    //     this.$Modal.confirm({
    //       title: "提示",
    //       content: "登录后才能查询、下载证书",
    //       okText: "去登录",
    //       onOk: () => {
    //         this.$router.push("/login?fromUrl=/certificate");
    //       },
    //       onCancel: () => {
    //         this.$router.go(-1);
    //       }
    //     });
    //   }
    // },
    // 退出登录
    logOut() {
      this.$store.commit("setToken", "");
      this.$store.commit("setUserInfo", {});
      window.localStorage.removeItem("login_time");
      this.$route.name != "StarIndex"
        ? this.$router.push("/dragon_star/index")
        : this.$router.go(0);
    },
    // 完善信息
    improve() {
      this.$Modal.confirm({
        title: "提示",
        content: "您需要登录设计瑰谷网站才能完善信息",
        okText: "去完善",
        onOk: function() {
          window.open(`${officialWebsiteUrl}#/login`);
        }
      });
    },
    // 去报名
    toSignUp() {
      this.isShow = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.dragonHome {
  padding-top: 60px;

  .dragonNav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;
    line-height: 60px;
    padding: 0 300px;
    text-align: center;
    z-index: 1000;
    background: #ffffff;

    .logo {
      position: absolute;
      left: 50px;
      top: 0;
      width: 225px;
      height: 60px;
      background-size: contain;
    }
    .navDiv {
      overflow: auto;
      white-space: nowrap;

      .nav {
        display: inline-block;
        margin: 0 25px;
        color: #404040;
        position: relative;
        cursor: pointer;
      }
    }

    .navDiv::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 2px;
    }
    .navDiv::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #777777;
    }
    .navDiv::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #eeeeee;
    }

    .signUp {
      position: absolute;
      right: 50px;
      top: 0;
      max-width: 250px;

      .mgLeft{
        margin-left: 10px;
      }

      .loginWrap {
        line-height: 1.8em;
        cursor: pointer;

        &::after {
          position: absolute;
          right: -10px;
          top: 4px;
          content: "\f33d";
          font-family: Ionicons;
        }
      }

      .userAvatar {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        vertical-align: middle;
        margin-right: 5px;
      }
      .userName {
        display: inline-block;
        width: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
  .contactWrap {
    padding: 40px 50px 50px;
    line-height: 1.8em;
    background: #7F7F7F;
    color: #ffffff;
    .footerTitle {
      font-weight: 600;
      color: #ffffff;
      padding-bottom: 5px;
    }
    .footerLabel {
      display: inline-block;
      padding-right: 15px;
    }
  }
  .linkWrap{
    padding: 40px 50px 50px;
    line-height: 1.8em;
    background: #515151;
    color: #fff;
    .linkTitle{
      padding-bottom: 15px;
      font-weight: 600;
    }
    .link{
      color: #fff;
    }
  }
  .copyright {
    padding: 10px 0;
    background: #7F7F7F;
    color: #fff;
    line-height: 1.8em;
  }
  .lgHidden {
    display: none;
  }
}
@media (max-width: 992px) {
  .dragonHome {
    .dragonNav {
      padding: 0 110px;

      .navDiv .nav {
        margin: 0 10px;
      }

      .logo {
        left: 15px;
      }
      .signUp {
        right: 15px;
      }
    }
    .contactWrap {
      .mobilePdtop {
        padding-top: 30px;
      }
    }
    .lgHidden {
      display: block;
    }
    .smHidden {
      display: none;
    }
  }
}
</style>